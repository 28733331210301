@use '@angular/material' as mat;

@include mat.elevation-classes();
@include mat.app-background();

// Typography
$qome-website-typography-config: mat.m2-define-typography-config(
    $font-family: 'DM Sans, Arial, sans-serif',
    $headline-5: mat.m2-define-typography-level(32px, 1.3, 500),
    $headline-6: mat.m2-define-typography-level(22px, 1.3, 500),
    $subtitle-1: mat.m2-define-typography-level(18px, 1.3, 500),
    $body-1: mat.m2-define-typography-level(16px, 1.5, 400),
    $body-2: mat.m2-define-typography-level(16px, 1.5, 400),
    $button: mat.m2-define-typography-level(16px, 1.5, 500)
);

// Colors
$qome-website-palette-primary: (
  50 : #ffede3,
  100 : #ffd1b9,
  200 : #ffb38b,
  300 : #ff945d,
  400 : #ff7d3a,
  500 : #FF5C0A,
  600 : #ff5e14,
  700 : #ff5311,
  800 : #ff490d,
  900 : #ff3807,
    contrast: (
        50 : #ffffff,
        100 : #ffffff,
        200 : #ffffff,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
    )
);
$qome-website-primary: mat.m2-define-palette($qome-website-palette-primary);
$qome-website-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);
$qome-website-warn: mat.m2-define-palette(mat.$m2-red-palette);

:root {
    $primary: map-get($qome-website-palette-primary, 500);
    --primary-color: #{$primary};
    --text-color: #2b2c3e;
}

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".

$qome-website-theme: mat.m2-define-light-theme((
  color: (
    primary: $qome-website-primary,
    accent: $qome-website-accent,
    warn: $qome-website-warn,
  ),
  typography: $qome-website-typography-config,
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

//@include mat.all-component-themes($qome-website-theme); // A optimiser en fonction des composants utilisés

@include mat.elevation-classes();
@include mat.app-background();
@include mat.icon-theme($qome-website-theme);
@include mat.form-field-theme($qome-website-theme);
@include mat.input-theme($qome-website-theme);
@include mat.expansion-theme($qome-website-theme);
@include mat.dialog-theme($qome-website-theme);
@include mat.slide-toggle-theme($qome-website-theme);

:root {
    @media (prefers-color-scheme: dark) {
        color-scheme: dark;
    }
    @media (prefers-color-scheme: light) {
        color-scheme: light;
    }
}

// Tailwindcss
@import "tailwindcss/base";
@import "tailwindcss/components";

a,
button {
    @apply transition-all duration-200;

    &:hover:not(.mat-ripple) {
        @apply text-qo-primary dark:text-qo-primary-200;
    }
}

img {
    @apply max-w-full;
}

.mat-mdc-dialog-container {
    @apply rounded-2xl dark:bg-qo-dark-700 dark:text-white #{!important};
}

.qo-img-crisp {
    image-rendering: -webkit-optimize-contrast;
}

.qo-lg-icon {
    font-size: 30px !important;
    height: 30px !important;
    width: 30px !important;
}

.qo-big-icon {
     font-size: 42px !important;
     height: 42px !important;
     width: 42px !important;
}

h1 {
    @apply font-title font-black text-4xl/tight sm:text-5xl/tight md:text-6xl/tight;
}

h2,
.qo-h2 {
    @apply font-title font-black text-3xl/tight sm:text-4xl/tight md:text-5xl/tight;
}

h3,
.qo-h3 {
    @apply font-title font-black text-2xl/tight sm:text-3xl/tight md:text-4xl/tight;
}

.qo-spacing {
    @apply px-5 sm:px-8;
}

.qo-spacing-sm {
    @apply sm:px-8;
}

.qo-spacing-md {
    @apply md:px-8;
}

.qo-MainBtn {
    @apply inline-flex text-white text-center font-medium bg-gradient-to-r from-qo-primary to-qo-primary-200
        rounded-full px-6 py-3 shadow-md filter hover:brightness-125 hover:text-white #{!important};
}

.qo-MainRoundedBtn {
    @apply inline-flex text-center font-medium rounded-full px-6 py-3 border border-solid
        border-qo-text dark:border-gray-300/30 filter hover:brightness-110 hover:bg-black/5 dark:hover:bg-white/5;
}

.qo-ClassicPage {

    h1, h2, h3 {
        @apply mt-6 mb-4;
    }

    p {
        @apply mb-4;
    }

    ul {
        @apply pl-10 mb-4 list-disc;
    }

    a {
        @apply underline;
    }
}

#crisp-chatbox {
    z-index: 900 !important;
}


//--------------------------- =MAINNAV

.qo-MainNav {
    li {
        &:hover,
        &:focus {
            div {
                @apply visible opacity-100;
            }
        }
    }
}

.qo-FixedNav {
    @apply bg-gray-200 border-solid dark:bg-qo-dark-800 fixed py-3 #{!important};
}

//--------------------------- =FORM

.qo-Contact {
    .mat-form-field-appearance-fill {
        label {
            @apply dark:text-white/80;
        }

        input {
            @apply dark:text-white;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-flex {
            @apply bg-white dark:bg-qo-dark-700;
        }
    }
}

//--------------------------- =FAQ

.qo-Faq {
    .mat-expansion-panel {
        @apply bg-transparent border-solid border-b border-qo-text/5 dark:border-white/10 shadow-none;

        &:last-child {
            @apply border-none;
        }
    }

    .mat-expansion-panel-spacing {
        @apply m-0;
    }

    .mat-expansion-panel-header {
        @apply px-4;
    }

    .mat-expansion-panel-header-title {
        @apply md:text-lg py-4 md:py-8 dark:text-white;
    }

    .mat-expanded {
        .mat-expansion-panel-header-title {
            @apply font-black text-qo-primary dark:text-qo-primary-200;
        }
    }

    .mat-expansion-indicator {
        @apply origin-center;

        &::after {
            @apply -mt-1  border-solid dark:text-white border-qo-text dark:border-white;
        }
    }

    .mat-expansion-panel-body {
        @apply dark:text-white;

        a {
            @apply font-bold;

            &:hover,
            &:focus {
                @apply no-underline;
            }
        }
    }
}

//--------------------------- =SLIDE TOGGLE

.mat-mdc-slide-toggle {
    label {
        @apply pl-2 cursor-pointer #{!important};
    }
}

//--------------------------- =SLIDER
/*
.qo-Slider {
    &.mat-slider-horizontal .mat-slider-wrapper {
        top: 18px;
    }

    &.mat-slider-horizontal .mat-slider-track-wrapper {
        @apply rounded-xl;
        height: 10px;
    }

    &.mat-slider-horizontal .mat-slider-track-background,
    &.mat-slider-horizontal .mat-slider-track-fill {
        @apply h-full;
    }

    &.mat-slider-horizontal .mat-slider-track-fill {
        @apply bg-qo-primary;
    }

    &.mat-slider-horizontal .mat-slider-track-background {
        @apply bg-qo-primary/10;
    }

    &.mat-slider-horizontal .mat-slider-thumb {
        @apply bg-white bg-center bg-no-repeat border-2 border-solid border-qo-primary h-10 w-10 -right-5 shadow-md scale-100 #{!important};
        background-image: url('assets/images/ico/slider-arrow.svg') !important;
        bottom: -24px;
    }

    &.mat-slider-horizontal .mat-slider-thumb-label {
        @apply bg-qo-text dark:bg-white rounded-full p-px top-auto transform-none #{!important};
        bottom: -62px;
        right: -28px;
        width: 54px;

        &:after {
            @apply absolute h-0 w-0 clear-both border-b-4 border-solid border-qo-text dark:border-white #{!important};
            border-left: 4px solid transparent !important;
            border-right: 4px solid transparent !important;
            content: "";
            left: 23px;
            top: -4px;
        }
    }

    .mat-slider-thumb-label-text {
        @apply text-white dark: text-sm font-medium opacity-100 transform-none #{!important};
    }
}
*/





@import "tailwindcss/utilities";
